import React from 'react'
import './experience.css'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';




const data = [
  {
    organization: 'BrandHob',
    date: 'July 2020 - Current',
    role: 'Web Developer',
    description: 'working remotely with a team of designers & developers to create websites for company clients. Converting design mockups and wireframes to fully functional websites. Fixing broken sites, adding new functionalities and performing other maintenance-related tasks.'
  },
  {
    organization: 'Freelance',
    date: 'June 2020 - Current',
    role: 'Web Developer',
    description: 'Working on a range of web projects and being actively involved in all processes from start to finish. Utilizing languages & technologies like HTML, CSS, JavaScript, WordPress to build static websites & dynamic websites including blogs, landing pages, corporate websites and ecommerce websites.'
  },
  
]

const Experience = () => {
  return (
    <section id='experience' className="exp__sec">
      <h2>Experience</h2>

      <Swiper className="container experience__container"
      modules={[ Pagination ]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {
          data.map(({organization, date, role, description}, index) => {
           return (
            <SwiperSlide key={index} className="experience">
            <div className="avatar">
              <h5>{organization}</h5>
              <h5>{date}</h5>
              <h3>{role}</h3>
              <p className="details">{description}</p>
            </div>
        </SwiperSlide>
           )
        })
        }
      
      </Swiper>
      </section>

      
  )
}

export default Experience