import React from 'react'
import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://github.com/Arnold-Omagbemi" target="_blank" rel="noopener
         noreferrer">
        <FaGithub />
        </a>

        <a href="https://www.linkedin.com/in/arnold-omagbemi-3703b2219/" target="_blank" rel="noopener
         noreferrer">
        <FaLinkedin />
        </a>

        <a href="https://twitter.com/_arnoldinho" target="_blank" rel="noopener
            noreferrer">
            <FaTwitter />
        </a>


    </div>
  )
}

export default HeaderSocials