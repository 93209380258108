import React from 'react'
import './contact.css'
import MAIL from '../../assets/laptopguy.png'
import { FaTwitter, FaDev, FaInstagram } from 'react-icons/fa';
import { BsMedium } from 'react-icons/bs';




const Contact = () => {
  return (
    <section id='contact' className="contact__sec">
      <h2>Contact Me</h2>

    <div className="container contact__container">
      <div className="left-section">
        <div className="img-section">
            <img src={MAIL} alt="Icon8" />
        </div>
      </div>
        <div className="right-section">
          <article className="contact__option">
            <p>Got a question or a proposal? Go ahead and send a message.</p>
            <br />
            <a href="mailto:arnoldomagbemi@gmail.com" target="_blank" rel="noopener
            noreferrer" className="sendmail">
              Send a message
            </a>
          </article>
            
            <div className="socials__header">
            <h4>Find out what I have been up to</h4> 
            </div>
            
          <div className="socials__contact">

          <a href="https://www.instagram.com/arny_noldy/" target="_blank" rel="noopener
                     noreferrer">
            <FaInstagram />
        </a>

        <a href="https://twitter.com/_arnoldinho/" target="_blank" rel="noopener
            noreferrer">
            <FaTwitter />
        </a>

        <a href="https://dev.to/arny_noldy/" target="_blank" rel="noopener
                     noreferrer">
            <FaDev />
        </a>

        <a href="https://medium.com/me/lists/" target="_blank" rel="noopener
                     noreferrer">
            <BsMedium />
        </a>

        

        </div>
        </div>
      </div>
    </section>
  )
}

export default Contact