import React from 'react'
import'./header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header> 
    <div className="container header__container">
     <h1>Hi, I'm Arnold Omagbemi</h1>
     <br />
      <p>A <span className="mkb">Web Developer </span>  
        passionate about building responsive, highly performant and accessible products.
        I enjoy working in teams and independently to solve problems.
      </p>
      <p>With every line of code I strive to make the web a beautiful place.</p>
      <br />
      <CTA />
      <br />
      <br />
      <HeaderSocials />
     {/* <a href="#contact" className="scroll__down">Scroll Down</a> */}
    </div>
    </header>
  )
}

export default Header