import React from 'react'
import './footer.css'
import { FaTwitter, FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">Arnold <span><i className="fas fa-code"></i></span></a>

      <ul className="permalinks">
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

     {/* <div className="footer__socials">
      <a href="https://github.com/Arnold-Omagbemi" target="_blank" rel="noopener
         noreferrer">
        <FaGithub />
        </a>

        <a href="https://www.linkedin.com/in/arnold-omagbemi-3703b2219/" target="_blank" rel="noopener
         noreferrer">
        <FaLinkedin />
        </a>

        <a href="https://twitter.com/Arnold__Arny" target="_blank" rel="noopener
            noreferrer">
            <FaTwitter />
        </a>

        <a href="https://www.instagram.com/hi_noldy/" target="_blank" rel="noopener
                     noreferrer">
            <FaInstagram />
        </a>
      </div> */}

      Illustration by <a href="https://icons8.com/illustrations/author/zD2oqC8lLBBA" target="_blank" rel="noopener
            noreferrer">
              Icons 8</a> from 
              <a href="https://icons8.com/illustrations">Ouch!</a>
       
      <br />
      <br />

      <div className="footer__copyright">
        <small>&copy; Arnold Omagbemi. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer