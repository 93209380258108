import React from 'react'
import './about.css'
import ReactIcon from '../../assets/icons8-react-40.png';
import HtmlIcon from '../../assets/icons8-html-5-48.png';
import CssIcon from '../../assets/icons8-css3-48.png';
import JsIcon from '../../assets/icons8-javascript-48.png'
import WordpressIcon from '../../assets/icons8-wordpress-48.png'
import TypescriptIcon from '../../assets/icons8-typescript-48.png'
import ME from '../../assets/arnold.jpg'


const About = () => {
  return (
    <section id='about' className="about__sec">
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About png" />
          </div>
        </div>

        <div className="about__content">
          <p>I'm a Web Developer
            currently living and working in Nigeria.
            I am proficient in utilizing languages and technologies such as JavaScript, TypeScript, React, Next.js, WordPress & Shopify to create user-facing components & fully functional web applications.
            As the web evolves every year, I ensure that I grow as well by reading and taking courses online.
            I work best in an agile software development environment, interacting and collaborating with diverse teams to meet customer's expectations and needs.
            When I'm not coding, reading, or writing articles on Dev.to or Medium, I watch basketball, football, play video games, listen to music and create amazing playlists.
          </p>
          <div className="stacks">
            <ul>
              <li>
                <img src={ReactIcon} alt='React-png' />
              </li>
              <li>
                <img src={JsIcon} alt='Javascript-png' />
              </li>
              <li>
                <img src={TypescriptIcon} alt='Typescript-png' />
              </li>
              <li>
                <img src={HtmlIcon} alt='Html-png' />
              </li>
              <li>
                <img src={CssIcon} alt='Css3-png' />
              </li>
              <li>
                <img src={WordpressIcon} alt='Wordpress-png' />
              </li>

            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About